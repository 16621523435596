.reviews {
  &__container {
    display: flex;
    flex-direction: column;
    z-index: 100;
  }
  &__title {
    text-align: left;
    @media (max-width: 600px) {
      text-align: center;
      margin-bottom: 32px;
    }
  }

  &__body {
    position: relative;
    display: flex;
    align-items: center;
    overflow-x: hidden;

    &::after {
      position: absolute;
      content: "";
      right: 0;
      z-index: 10;
      width: 30px;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(21, 21, 21, 1) 100%
      );
    }
    &_left {
      display: flex;
      .left_arrow,
      .right_arrow {
        cursor: pointer;
        margin-right: 60px;
        transition: all 0.1s ease;
        &:hover {
          transform: scale(0.97);
        }
        &:active {
          transform: scale(0.9);
        }
      }

      @media (max-width: 600px) {
        display: none;
      }
    }

    &_right {
      position: relative;
      &_swipper {
        max-width: 75vw;
      }
      &_slide {
        width: 416px;
        background: #242424;
        border-radius: 20px;
        padding: 40px;
        color: rgba(255, 255, 255, 0.6);
        &_review {
          text-align: left;
          p {
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
          }
        }
        &_info {
          display: flex;
          justify-content: space-between;
          margin-top: 40px;
        }
      }
      @media (max-width: 600px) {
        width: 100vw;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        &_swipper {
          display: flex;
          justify-content: center;
          width: 280px;
        }

        &_slide {
          max-width: 280px;
          font-size: 14px;
          padding: 20px;
          &_review {
            p {
              font-size: 14px;
              line-height: 24px;
            }
          }

          &_info {
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
