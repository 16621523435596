@import "./variables.scss";
.App {
  text-align: center;
  background-color: #151515;
  overflow-x: hidden;
}

h1 {
  font-weight: 600;
  font-size: 56px;
  color: #fff;

  @media (max-width: 600px) {
    font-size: 32px;
  }
}

h3 {
  font-weight: 400;
  font-size: 24px;
  color: #fff;
}

section {
  max-width: 1320px;
  margin: 100px auto 0 auto;

  @media (max-width: 600px) {
    margin: 46px 10px;
  }
}

button {
  cursor: pointer;
  outline: none;
  padding: 0.5rem 1.25rem;
  border-radius: $borderRadius;
  font-size: 1rem;
  font-family: inherit;
  background: $primary;
  color: $light;
  border: 1px solid transparent;
  transition: 0.3s ease all;

  &:hover {
    border-color: $primary;
    background: rgba($primary, 0.1);
    color: $primary;
  }
  &:active {
    border-color: $primary;
    background: linear-gradient(rgba($primary, 0.2), rgba($primary, 0.3));
    color: $primary;
  }
}

.main {
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    &-img {
      display: flex;
      border-radius: 14px;
      overflow: hidden;
      margin-top: 30px;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
    justify-content: center;
    color: #fff;
    font-weight: 400;
    font-size: 20px;
    max-width: 526px;

    h1 {
      margin-bottom: 64px;
    }

    p {
      margin-bottom: 56px;
      opacity: 0.6;
    }

    ul {
      line-height: 64px;
      padding-left: 40px;
      margin-bottom: 56px;
      li {
        position: relative;
        &::before {
          position: absolute;
          content: "";
          left: -40px;
          top: 50%;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          border-radius: 100%;
          background: #3164f4;
        }
      }
    }

    @media (max-width: 600px) {
      text-align: center;
      align-items: center;

      h1 {
        margin-bottom: 32px;
      }
    }
  }

  &__right {
    img {
      max-width: 400px;
      @media (max-width: 600px) {
        max-width: 250px;
        margin-top: 50px;
      }
    }
  }
}

.life {
  &__container {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    @media (max-width: 600px) {
      flex-direction: column-reverse;
      justify-content: center;
    }
  }

  &__left {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    img {
      border-radius: 14px;
      width: 300px;
      height: 300px;
      object-fit: cover;
      @media (max-width: 600px) {
        width: 150px;
        height: 150px;
        margin-top: 50px;
      }
    }
  }
  &__right {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
    text-align: start;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
    font-size: 20px;
    max-width: 458px;
    @media (max-width: 600px) {
      align-items: center;
      text-align: center;
      justify-content: center;
      max-width: 100%;
    }
    h1 {
      margin-bottom: 64px;
    }

    p {
      margin-bottom: 26px;
    }

    ul {
      line-height: 44px;
      padding-left: 40px;
      margin-bottom: 56px;
      li {
        position: relative;
        &::before {
          position: absolute;
          content: "";
          left: -40px;
          top: 50%;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          border-radius: 100%;
          background: #3164f4;
        }
      }
    }
  }
}

.it {
  h1 {
    font-size: 2rem;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 2rem;
  }

  &__right {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 400px;
    gap: 1rem;

    img {
      border-radius: 14px;
      width: 300px;
      height: 300px;
      object-fit: cover;
      @media (max-width: 600px) {
        width: 150px;
        height: 150px;
        margin-top: 50px;
      }
    }
  }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
    font-size: 20px;
    max-width: 663px;
    @media (max-width: 600px) {
      justify-content: center;
      align-items: center;

      p {
        text-align: center;
        margin: 20px 0;
      }
    }

    h1 {
      margin-bottom: 64px;
    }

    p {
      margin-bottom: 56px;
    }

    ul {
      // line-height: 64px;
      padding-left: 40px;
      margin-bottom: 56px;
      li {
        position: relative;
        margin-bottom: 15px;
        &::before {
          position: absolute;
          content: "";
          left: -40px;
          top: 15%;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          border-radius: 100%;
          background: #3164f4;
        }
      }
    }
  }
}

.advantages {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__title {
    margin-bottom: 80px;
  }
  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &_item {
      background: #242424;
      border-radius: 20px;
      margin: 12px;
      padding: 56px 40px;
      max-width: 416px;
      h3 {
        margin-bottom: 60px;
        position: relative;
        text-decoration: underline #3164f4;
        text-decoration-thickness: 4px;
        text-underline-offset: 16px;
      }
      p {
        font-weight: 400;
        font-size: 20px;
        color: #fff;
        opacity: 0.6;
      }
    }
  }
}

.how {
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  &__left {
    img {
      border-radius: 14px;
      max-width: 400px;
      @media (max-width: 600px) {
        max-width: 250px;
        margin-top: 50px;
      }
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: start;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
    font-size: 20px;
    max-width: 458px;

    h1 {
      margin-bottom: 64px;
    }

    p {
      margin-bottom: 56px;
    }

    ul {
      line-height: 32px;
      padding-left: 40px;
      margin-bottom: 56px;
      li {
        position: relative;
        margin: 12px 0;
        &::before {
          position: absolute;
          content: "";
          left: -40px;
          top: 50%;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          border-radius: 100%;
          background: #3164f4;
        }
      }
    }
    @media (max-width: 600px) {
      justify-content: center;
      align-items: center;

      p {
        text-align: center;
        margin: 20px 0;
      }
    }
  }
}

.contact {
  &__container {
    max-width: 416px;
    margin: 0 auto;
  }
}

footer {
  padding: 40px 0;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0;
    color: rgba(255, 255, 255, 0.6);
    a {
      margin: 0 20px;
    }
  }

  .cop {
    color: rgba(255, 255, 255, 0.6);
  }
}
