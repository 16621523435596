$Poppins: "Poppins", sans-serif;

// colors
$dark: rgb(27, 27, 27);
$light: #fff;
$primary: #3164f4;
$bg: rgb(244, 244, 255);

// spacing
$spacing-md: 16px;
$spacing-lg: 32px;

// border radius
$borderRadius: 12px;

// header height
$headerHeight: 80px;

@mixin breakpoint($point) {
  @if $point == md {
    // 768px
    @media (min-width: 48em) {
      @content;
    }
  }
}
